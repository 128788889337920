/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-project-management.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Project Management"
      description="If your business is project-driven, your managers need to keep close control of project performance, costs and revenues, and labor, as well as other resources."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Control your Projects",
          text: `If your business is project-driven, your managers need to keep close control of project performance, costs and revenues, and labor, as well as other resources. Microsoft Dynamics Business Central helps you plan and manage jobs and projects with support for budgeting, billing, and resource management. You can manage project costs efficiently by tracking resource expenses and usage, planning capacity, and predicting availability. By monitoring projects effectively, you can make confident, informed decisions based on comprehensive information.`,
        },
        {
          title: "Control costs and improve profitability",
          text: `Microsoft project management solutions can help managers and staff structure and run projects with optimal cost-efficiency and profitability. With these innovative software tools, they can create just the billing structures they need and track the actual expenses of labor, materials, and other costs more easily. Your finance department can ensure a healthy cash flow by reducing the length of billing cycles, always staying on top of unbilled receivable amounts and transforming them into revenue. The solutions also allow them to be constantly aware of changes in key values such as costs or margins, so they can take needed action promptly.`,
        },
        {
          title: "Streamline project management",
          text: `With Microsoft project management solutions, your people can track project progress, identify issues, and implement changes quickly. They can accelerate project workflows through automation and make important project information available to all stakeholders with immediacy. These solutions can integrate with the business management software already at work in your financial management, human resources, or other lines of business, so people can spend less time administering business processes, and more time pursuing new business opportunities. The Microsoft project management solutions also offer the flexibility to adjust to the needs of different business roles in project management.`,
        },
        {
          title: "Achieve optimal productivity from your resources",
          text: `With the assistance of Microsoft project management solutions, you can manage your employees, contractors, partner relationships, and material resources to be in line with project goals and client expectations. Using all of your resources to best effect can help you complete projects on or under budget and on time or early, providing additional opportunities for winning new projects and running them successfully. From the first stages of project planning to completion, Microsoft solutions can contribute to an increase in the business value of the resources on each project.`,
        },
      ]}
    />
  )
}
